import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { TextField } from 'formik-material-ui';
import { FormMessages } from 'constants/Common';
import { ChannelRole } from 'app/domain/ChannelDetails';
import MultiSelect from 'app/components/inputs/MultiSelect';
import { Category } from 'app/domain/Category';
import { Contributor } from 'app/domain/Contributor';
import { useAllCategories } from 'categories/categoriesUseCase';
import { prepareCeilingCategories } from 'categories/tree/categoriesTreeManager';
import { FEATURE_FLAGS } from 'app/featureFlags/featureFlags';
import { useFeatureFlags } from '@content-technology-partners-ltd/shared-flags-react';

const ValidationSchema = Yup.object().shape({
	name: Yup.string().max(100, FormMessages.TOO_LONG).required(FormMessages.REQUIRED),
	description: Yup.string().max(500, FormMessages.TOO_LONG)
});

export interface EditChannelValues {
	name?: string;
	description?: string;
	initialRoles?: ChannelRole[];
	roles?: ChannelRole[];
	ceilingCategoriesIds?: number[];
	defaultContributors?: Contributor[];
}

export interface ChannelRolesSelectItem {
	label: string;
	data: {
		name: ChannelRole;
	};
}

const mapRoleToRoleSelectItem = (role: string): ChannelRolesSelectItem => {
	return {
		label: role,
		data: { name: role as ChannelRole }
	};
};

const rolesItems = [
	'ADVERTISER',
	'LIVESTREAMER',
	'DEVICESTREAMER',
	'PUBLISHER',
	'AGENT',
	'LIVECHAT',
	'COMMENTS',
	'EMBEDDED'
].map(mapRoleToRoleSelectItem);

interface CategorySelectItem {
	label: string;
	data: {
		id: number;
		name: string;
	};
}

const mapCategoryToCategorySelectItem = (category: Category): CategorySelectItem => {
	return {
		label: category.name,
		data: {
			id: category.id,
			name: category.name
		}
	};
};

interface Props {
	values: EditChannelValues;
	isOpen: boolean;
	closeModal: () => void;
	save: (values: EditChannelValues) => void;
}

const EditChannelModal = ({ values, isOpen, closeModal, save }: Props) => {
	const { categories } = useAllCategories();

	const [categoriesItems, setCategoriesItems] = useState<CategorySelectItem[]>([]);
	const selectedCategoriesItems = categoriesItems.filter(el => values.ceilingCategoriesIds?.includes(el.data.id));
	const roles = values.roles;

	const [{ enabled: isCommentsRoleEnabled }] = useFeatureFlags([FEATURE_FLAGS.ENABLE_COMMENTS_ROLE]);
	const filteredRoles = isCommentsRoleEnabled ? rolesItems : rolesItems.filter(role => role.label !== 'COMMENTS');

	const selectRolesItems = (roles: string[]) => filteredRoles.filter(el => roles.includes(el.label));
	const selectedRolesItems = roles ? selectRolesItems(roles) : [];

	useEffect(() => {
		categories && setCategoriesItems(prepareCeilingCategories(categories).map(mapCategoryToCategorySelectItem));
	}, [categories]);

	return (
		<Dialog className="dialog" open={isOpen} onClose={closeModal} fullWidth={true} maxWidth={'lg'}>
			<Formik
				enableReinitialize={true}
				initialValues={{
					initialRoles: roles,
					...values
				}}
				validationSchema={ValidationSchema}
				onSubmit={v => {
					save(v);
					closeModal();
				}}
			>
				{({ setFieldValue }) => (
					<Form>
						<DialogTitle>Edit channel</DialogTitle>
						<DialogContent>
							<DialogContentText>This action might confuse partner so do it sparingly!</DialogContentText>
							<Field
								className="formField"
								fullWidth
								label="New name"
								name="name"
								variant="outlined"
								component={TextField}
							/>
							<Field
								className="formField"
								fullWidth
								label="New description"
								name="description"
								variant="outlined"
								component={TextField}
							/>
							<MultiSelect
								label="Roles"
								initialSelected={selectedRolesItems}
								items={filteredRoles}
								setItems={items =>
									setFieldValue(
										'roles',
										items.map(el => el.data.name)
									)
								}
							/>
							<MultiSelect
								label="Ceiling categories"
								initialSelected={selectedCategoriesItems}
								items={categoriesItems}
								setItems={items =>
									setFieldValue(
										'ceilingCategoriesIds',
										items.map(el => el.data.id)
									)
								}
							/>
						</DialogContent>
						<DialogActions className="dialogActions">
							<Button variant="contained" onClick={closeModal} className="button-cancel">
								Cancel
							</Button>
							<Button type="submit" variant="contained" color="secondary" className="button-yes">
								Save
							</Button>
						</DialogActions>
					</Form>
				)}
			</Formik>
		</Dialog>
	);
};

export default EditChannelModal;
