import { AppThunkDispatch } from 'app/store/store';
import { clearAuthTokens } from 'app/http/authApi/token';
import { logoutAction } from 'app/store/user/actions';
import { redirectAction } from 'app/store/app/actions';
import RoutePaths from 'app/navigation/RoutePaths';
import { identifyUserStop } from '@content-technology-partners-ltd/shared-flags-react';

const logoutUseCase = (dispatch: AppThunkDispatch) => {
	clearAuthTokens();
	dispatch(logoutAction());
	identifyUserStop();
	dispatch(redirectAction(RoutePaths.Login));
};

export default logoutUseCase;
