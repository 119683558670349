import { ConfigFromEnvVariables } from 'config/appConfig';

const devConfig: ConfigFromEnvVariables = {
	EMBEDDED_WIDGET_SCRIPT_URL: 'https://embedded.tst.recast.tv/iframe/resizer.js',
	ENDPOINTS_API: 'https://the.tst.recast.app/api/endpoints',
	FALLBACK_API_BASE: 'https://the.tst.recast.app',
	FALLBACK_CUBEJS_BASE: 'https://cubejs.tst.recast.app',
	FALLBACK_EMBEDDED_BASE: 'https://embedded.recast.tv',
	FALLBACK_FAN_APP_BASE: 'https://the.tst.recast.app',
	FALLBACK_SHARE_BASE: 'https://share-tst.recast.app',
	RTMP_BASE_URL: 'rtmp://rtmp.tst.recast.tv/live',

	FLAGSMITH_ENVIRONMENT_ID: 'BPQEXJqsijYV2FgxjTjWiJ',
	FLAGSMITH_API_URL: 'https://watch.recast.tv/flagsmith/api/v1/',
	FLAGSMITH_POLLING_INTERVAL_MS: 300000
};

export default devConfig;
