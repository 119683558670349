import { ConfigFromEnvVariables } from 'config/appConfig';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const APP_ENV: any;

const prodConfig: ConfigFromEnvVariables = {
	EMBEDDED_WIDGET_SCRIPT_URL: APP_ENV.EMBEDDED_WIDGET_SCRIPT_URL,
	ENDPOINTS_API: APP_ENV.ENDPOINTS_API,
	FALLBACK_API_BASE: APP_ENV.FALLBACK_API_BASE,
	FALLBACK_CUBEJS_BASE: APP_ENV.FALLBACK_CUBEJS_BASE,
	FALLBACK_EMBEDDED_BASE: APP_ENV.FALLBACK_EMBEDDED_BASE,
	FALLBACK_FAN_APP_BASE: APP_ENV.FALLBACK_FAN_APP_BASE,
	FALLBACK_SHARE_BASE: APP_ENV.FALLBACK_SHARE_BASE,
	RTMP_BASE_URL: APP_ENV.RTMP_BASE_URL,

	FLAGSMITH_ENVIRONMENT_ID: APP_ENV.FLAGSMITH_ENVIRONMENT_ID,
	FLAGSMITH_POLLING_INTERVAL_MS: APP_ENV.FLAGSMITH_POLLING_INTERVAL_MS,
	FLAGSMITH_API_URL: APP_ENV.FLAGSMITH_API_URL
};
export default prodConfig;
