import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Box, Container, Typography } from '@mui/material';
import { PublicAccountLine, Type } from 'accounts/apiTypes';
import AmountDisplay from 'accounts/AmountDisplay';
import { VideoListing } from 'app/domain/VideoListing';
import {
	getPurchaseTransactionsVideosByTariffId,
	getTransactionsData,
	getUserPurchaseRefundsByLedgerId,
	getUserPurchasesByLedgerId,
	RefundedPurchase
} from 'accounts/AccountDetailsUseCase';
import RefundButton from 'refunds/action/RefundButton';
import RefundConfirmationModal from 'refunds/action/RefundConfirmationModal';
import { FEATURE_FLAGS } from 'app/featureFlags/featureFlags';
import AdminApiPagination from '../app/components/pagination/AdminApiPagination';
import { useLocalQuery } from '../app/utils/searchManager';
import { AdminApiPage } from '../app/domain/AdminApiPage';
import { PurchaseApiEntity } from 'app/domain/Purchase';
import { useFeatureFlags } from '@content-technology-partners-ltd/shared-flags-react';

const renderDate = (date?: string) => {
	if (!date) return '';

	return date.substr(0, 10) + ' ' + date.substr(11, 8) + ' UTC';
};

const isPlayOrAdTransaction = (type: Type) => type === Type.PLAY || type === Type.AD;
const isPurchaseTransaction = (type: Type) => type === Type.PURCHASE;
const isPurchaseRefundTransaction = (type: Type) => type === Type.PURCHASE_REFUND;

const renderTransactionDetails = (
	transaction: PublicAccountLine,
	videoListing?: VideoListing,
	purchase?: PurchaseApiEntity
) => {
	const type = transaction.ledger.type;
	const purchaseMetadata = purchase?.metadata;

	if (isPlayOrAdTransaction(type)) {
		return <Link to={`/video/${transaction.ledger.linkedId}`}>video: {transaction.ledger.linkedId}</Link>;
	} else if ((isPurchaseTransaction(type) || isPurchaseRefundTransaction(type)) && videoListing) {
		return (
			<Link to={`/video/${videoListing.videoUid}`}>
				video: {videoListing.videoUid}
				{isPurchaseTransaction(type) && (
					<>
						{','} <br /> title: {videoListing.name}
						{purchaseMetadata?.source && (
							<>
								{' '}
								{','} <br /> source: {purchaseMetadata?.source}
							</>
						)}
						{purchaseMetadata?.widgetId && (
							<>
								{' '}
								{','} <br /> widgetId: {purchaseMetadata?.widgetId}
							</>
						)}
						{purchaseMetadata?.channelId && (
							<>
								{' '}
								{','} <br /> channelId: {purchaseMetadata?.channelId}
							</>
						)}
					</>
				)}
			</Link>
		);
	}
	return transaction.ledger.linkedId;
};

const renderTransactions = ({
	transaction,
	isRefundsEnabled,
	userId,
	videoListing,
	refundedPurchase,
	purchase
}: {
	transaction: PublicAccountLine;
	isRefundsEnabled: boolean;
	userId?: string;
	videoListing?: VideoListing;
	refundedPurchase?: RefundedPurchase;
	purchase?: PurchaseApiEntity;
}) => {
	const reason = transaction.ledger.description;
	const type = transaction.ledger.type;
	return (
		<tr key={transaction.index}>
			<td>{renderDate(transaction.ledger.created)}</td>
			<td>{transaction.index}</td>
			<td>
				{transaction.ledger.type} {transaction.type}
			</td>
			<td>
				{!isPurchaseRefundTransaction(type) && (
					<>
						description: {transaction.description ? `${transaction.description}, ` : ` `} <br />{' '}
					</>
				)}
				{isPurchaseRefundTransaction(type) && (
					<>
						{' '}
						description: <br /> Refund for{' '}
					</>
				)}
				{renderTransactionDetails(transaction, videoListing, purchase)} <br />
				{reason ? `reason: ${reason}` : ''}
			</td>

			<td>
				<AmountDisplay amt={transaction.amt} />
			</td>
			<td>
				<AmountDisplay amt={transaction.balance} />
			</td>
			{userId && isRefundsEnabled && (
				<td>
					<RefundButton
						transaction={transaction}
						userId={userId}
						existingRefund={refundedPurchase}
						videoListing={videoListing}
					/>
				</td>
			)}
		</tr>
	);
};

const AccountDetailsScreen = (props: { id?: number; userUid?: string }) => {
	const paramId = useParams<{ id: string }>().id;
	const id = props.id || parseInt(paramId);
	const userId = props.userUid;

	const [url, setUrl] = useState<string>();

	const [{ enabled: isRefundsEnabled }] = useFeatureFlags([FEATURE_FLAGS.ENABLE_PURCHASE_REFUNDS]);

	const [purchaseVideosByTariffId, setPurchaseVideosByTariffId] = useState<Record<string, VideoListing>>({});
	const [purchaseRefundsByLedgerId, setPurchaseRefundsByLedgerId] = useState<Record<number, RefundedPurchase>>({});
	const [purchaseByLedgerId, setPurchaseByLedgerId] = useState<Record<number, PurchaseApiEntity>>({});

	const [data] = useLocalQuery<AdminApiPage<PublicAccountLine>>(() => getTransactionsData(id, url), [id, url]);

	const changePage = (url: string) => {
		setUrl(url);
	};

	const processTransactions = async (id: number, url?: string) => {
		const transactionsData = await getTransactionsData(id, url);
		const transactions = transactionsData.items;
		setPurchaseVideosByTariffId(await getPurchaseTransactionsVideosByTariffId(transactions));
		if (userId) {
			setPurchaseRefundsByLedgerId(await getUserPurchaseRefundsByLedgerId(userId));
			setPurchaseByLedgerId(await getUserPurchasesByLedgerId(userId));
		}
	};

	useEffect(() => {
		processTransactions(id, url).then();
	}, [id]);

	return (
		<Container className="walletTransactions">
			<Typography className="walletTransactions__title" variant="h5">
				Id: {id}
			</Typography>

			<table className="walletTransactions__table">
				<thead className="walletTransactions__tableHeader">
					<tr>
						<td style={{ width: '12em' }}>date</td>
						<td style={{ width: '4em' }}>line</td>
						<td style={{ width: '12em' }}>type</td>
						<td style={{ width: '22em' }}>details</td>
						<td style={{ width: '8em' }}>amount</td>
						<td style={{ width: '8em' }}>balance</td>
						{userId && isRefundsEnabled && <td style={{ width: '8em' }}>refund</td>}
					</tr>
				</thead>

				<tbody>
					{data &&
						data.items.map(transaction =>
							renderTransactions({
								transaction,
								userId,
								isRefundsEnabled,
								videoListing: purchaseVideosByTariffId[transaction.ledger.linkedId || ''],
								refundedPurchase: purchaseRefundsByLedgerId[transaction.ledger.id],
								purchase: purchaseByLedgerId[transaction.ledger.id]
							})
						)}
				</tbody>
			</table>
			<Box justifyContent="center" className="paginationContainer">
				{data && <AdminApiPagination items={data.items} paging={data.paging} changePage={changePage} />}
			</Box>
			<RefundConfirmationModal onRefresh={() => processTransactions(id, url)} />
		</Container>
	);
};

export default AccountDetailsScreen;
