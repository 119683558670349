import { ConfigFromEnvVariables } from 'config/appConfig';

const localConfig: ConfigFromEnvVariables = {
	EMBEDDED_WIDGET_SCRIPT_URL: 'https://embedded.tst.recast.tv/iframe/resizer.js',
	ENDPOINTS_API: 'http://localhost:3265/api/endpoints',
	FALLBACK_API_BASE: 'http://localhost:8071/v2',
	FALLBACK_CUBEJS_BASE: '/v1',
	FALLBACK_EMBEDDED_BASE: 'http://recast.tv.local:3000',
	FALLBACK_FAN_APP_BASE: 'http://recast.app.local:3000',
	FALLBACK_SHARE_BASE: 'https://share-dev.recast.app',
	RTMP_BASE_URL: 'rtmp://rtmp.tst.recast.tv/live',

	FLAGSMITH_ENVIRONMENT_ID: 'BPQEXJqsijYV2FgxjTjWiJ',
	FLAGSMITH_API_URL: 'https://watch.recast.tv/flagsmith/api/v1/',
	FLAGSMITH_POLLING_INTERVAL_MS: 300000
};

export default localConfig;
